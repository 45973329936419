import React from 'react';
import {graphql} from 'gatsby';
import Layout from '@solid-ui-layout/Layout';
import Seo from '@solid-ui-components/Seo';
import Divider from '@solid-ui-components/Divider';
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01';
import Header from '@solid-ui-blocks/Header/Block01';
import Footer from '@solid-ui-blocks/Footer/Block01';
import {normalizeBlockContentNodes} from '@blocks-helpers';
import {Container} from "theme-ui";
import ContentOne from '@solid-ui-blocks/Content/Block01';
import theme from "../_theme";

const PersonalDataPolicy = props => {
    const {allBlockContent} = props.data
    const content = normalizeBlockContentNodes(allBlockContent?.nodes)

    return (
        <Layout theme={theme} {...props}>
            <Seo title={'Protection des données personnelles'} keywords={['Agence et Factory Digitale', 'conception des sites internet', 'e-commerce', 'développement des application web', 'développement des applications desktop', 'développement des application mobile', 'crm', 'erp', 'logiciels']} />
            {/* Modals */}
            <ModalWithTabs content={content['authentication']} reverse/>
            <ModalWithTabs content={content['contact']}/>
            {/* Blocks */}
            <Header content={content['header']}/>

            <Divider space='6'/>
            <Container variant='narrow'>
                <ContentOne content={content['personal-data-policy']}/>
            </Container>

            <Divider space='5'/>
            <Footer content={content['footer']}/>
        </Layout>
    )
}

export const query = graphql`
  query personalDataPolicyBlockContent {
    allBlockContent(
      filter: { page: { in: ["infinity/about-us", "infinity/shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default PersonalDataPolicy
